<template>
  <div class="directflightsonly_top directbottom">

    <div :class="{'border p-2 mb-2': isMultiCity}" v-for="(item, inx) in cityCount" :key="inx">
      <div class="btn-group">
        <button @click="() => $bvModal.show(`destFrom${inx}`)" class="btn btnoutline" type="button">
          <strong>{{$t("sabre.search-panel.from-where")}}</strong>
        <span><i class="fa-solid fa-location-dot"></i></span>{{ showString(inx, true) }}
        </button>
      </div>

      <div class="btn-group">
        <button @click="() => $bvModal.show(`destTo${inx}`)" class="btn btnoutline" type="button">
          <strong>{{$t("sabre.search-panel.where")}}</strong>
          <span><i class="fa-solid fa-location-dot"></i></span>{{ showString(inx, false) }}
        </button>
      </div>
    </div>

    <b-modal
      id="destFrom0"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="adjustbtnModal"
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="() => close()"></button>
        <div class="filtter_head">
          <h3><span><i class="fa-solid fa-location-dot"></i></span> {{$t("sabre.search-panel.from-where")}}</h3>
        </div>
      </template>

      <template #default="{ close }">
        <select-destination :isFrom="true" propsClassName="text-right filttermobailboby fromwheredropdown" type="modal"/>

        <div class="adjust_fillterbox_footer">
          <div class="btn-group-area d-flex justify-content-center">
            <button class="save_btn" @click="() => close()">{{$t("sabre.buttons.save")}}</button>
          </div>
        </div>

      </template>
    </b-modal>

    <b-modal
      id="destFrom1"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="adjustbtnModal"
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="close()"></button>
        <div class="filtter_head">
          <h3><span><i class="fa-solid fa-location-dot"></i></span> {{$t("sabre.search-panel.from-where")}}</h3>
        </div>
      </template>

      <template #default="{ close }">
        <select-destination :isFrom="true" :isMultiCity="isMultiCity" propsClassName="text-right filttermobailboby fromwheredropdown" type="modal"/>

        <div class="adjust_fillterbox_footer">
          <div class="btn-group-area d-flex justify-content-center">
            <button class="save_btn" @click="close()">{{$t("sabre.buttons.save")}}</button>
          </div>
        </div>

      </template>
    </b-modal>

    <b-modal
      id="destTo0"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="adjustbtnModal"
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="close()"></button>

        <div class="filtter_head">
          <h3><span><i class="fa-solid fa-location-dot"></i></span> {{$t("sabre.search-panel.where")}}</h3>
        </div>
      </template>

      <template #default="{ close }">
        <select-destination propsClassName="text-right filttermobailboby fromwheredropdown" type="modal"/>

        <div class="adjust_fillterbox_footer">
          <div class="btn-group-area d-flex justify-content-center">
              <button class="save_btn" @click="close()">{{$t("sabre.buttons.save")}}</button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="destTo1"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="adjustbtnModal"
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="close()"></button>

        <div class="filtter_head">
          <h3><span><i class="fa-solid fa-location-dot"></i></span> {{$t("sabre.search-panel.where")}}</h3>
        </div>
      </template>

      <template #default="{ close }">
        <select-destination :isMultiCity="isMultiCity" propsClassName="text-right filttermobailboby fromwheredropdown" type="modal"/>

        <div class="adjust_fillterbox_footer">
          <div class="btn-group-area d-flex justify-content-center">
              <button class="save_btn" @click="close()">{{$t("sabre.buttons.save")}}</button>
          </div>
        </div>
      </template>
    </b-modal>

    <div class="btn-group">
      <button v-b-modal.filtermodal6 class="btn btnoutline" type="button"><strong>{{$t("sabre.search-panel.when")}}</strong>
        <span><i class="fa-regular fa-calendar"></i></span> {{dateString}}
      </button>

    </div>

    <b-modal
      id="filtermodal6"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="adjustbtnModal"
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="close()"></button>

        <div class="filtter_head">
          <h3><span><i class="fa-regular fa-calendar"></i></span> {{$t("sabre.search-panel.when")}}</h3>
        </div>
      </template>

      <template #default="{ close }">
        <div class="text-right filttermobailboby">
          <div class="compositionarea">
            <search-date-tabs @update="(str)=> {dateString = str;}"/>
          </div>

        </div>
        <div class="adjust_fillterbox_footer">
          <div class="btn-group-area d-flex justify-content-center">
            <button class="cleanup_btn">{{$t("sabre.buttons.clean")}}</button>
            <button class="save_btn" @click="close()">{{$t("sabre.buttons.save")}}</button>
          </div>
        </div>
      </template>
    </b-modal>

    <div class="btn-group">
      <button v-b-modal.filtermodal7 class="btn btnoutline" type="button"><strong>{{$t("sabre.search-panel.passenger-selection")}} </strong>
        <span><i class="fa-solid fa-user"></i></span>
      {{compositionString.length > 15 ? `${compositionString.slice(0,15)} ...` : compositionString}}
      </button>
    </div>

    <b-modal
      id="filtermodal7"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="adjustbtnModal modal-dialog-centered"
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="close()"></button>

        <div class="filtter_head">
          <h3><span><i class="fa-solid fa-user"></i></span> 2 {{$t("sabre.composition.adults")}}</h3>
        </div>
      </template>
      <template #default="{ close }">
        <select-passenger propsClassName="text-right mx-3 mb-3 filttermobailboby" type="modal"/>

        <div class="adjust_fillterbox_footer">
          <div class="btn-group-area d-flex justify-content-center">
            <button class="save_btn" @click="close()">{{$t("sabre.buttons.save")}}</button>
          </div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BModal } from 'bootstrap-vue';
import dayjs from 'dayjs';

export default {
  name: 'search-mobile-down-pane',
  components: {
    BModal,
    SelectDestination: () => import('../searchPanelAtom/selectDestinations/selectDestination'),
    SearchDateTabs: () => import('../dateRange/searchDateTabs'),
    SelectPassenger: () => import('../searchPanelAtom/passenger/selectPassenger'),
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      // category: 'GET_CURRENT_CATEGORY',
      countries: 'GET_SABRE_COUNTRIES',
      destinationList: 'GET_DESTINATION_IMAGES',
      chosenStoreDest: 'GET_SABRE_CHOSEN_DESTINATIONS',
      chosenMultiCityStoreDest: 'GET_SABRE_CHOSEN_MULTI_DESTINATIONS',

      calendarTypeList: 'GET_SABRE_CALENDAR_TYPE_LIST',
      calendarType: 'GET_SABRE_CALENDAR_TYPE',
      exactDateRange: 'GET_SABRE_EXACT_DATE_RANGE',
      specificDateRange: 'GET_SABRE_SPECIFIC_DATE_RANGE',
      fullMonths: 'GET_SABRE_FULL_MONTHS',
      isMultiCity: 'GET_SABRE_IS_MULTI_CITY_STATE',

      adults: 'GET_SABRE_ADULTS_FOR_SEARCH',
      children: 'GET_SABRE_CHILDREN_FOR_SEARCH',
      babies: 'GET_SABRE_BABIES_FOR_SEARCH',
      seniors: 'GET_SABRE_SENIORS_FOR_SEARCH',
    }),
    dateString() {
      const { calendarTypeList, calendarType, exactDateRange, specificDateRange, fullMonths } = this;
      let str = '';
      if (calendarType === calendarTypeList[0]) {
        str = (exactDateRange[0] && exactDateRange[1]) ? `${this.convertDateFormat(exactDateRange[0])} - ${this.convertDateFormat(exactDateRange[1])}` : '';
      }
      if (calendarType === calendarTypeList[1]) {
        str = (specificDateRange[0] && specificDateRange[1]) ? `${this.convertDateFormat(specificDateRange[0])} - ${this.convertDateFormat(specificDateRange[1])}` : '';
      }
      if (calendarType === calendarTypeList[2]) {
        const last = fullMonths.length - 1;
        str = fullMonths.length ? `${fullMonths[0] || ''} ~ ${fullMonths[last] || 0}` : '';
      }
      return str;
    },
    cityCount() {
      return (this.isMultiCity) ? 2 : 1;
    },
    destinations() {
      const { destinationList } = this;
      // return destinationList?.filter((dest) => dest.forSabreFlight) || [];
      return destinationList?.filter((dest) => dest.name) || [];
    },
    compositionString() {
      const { adults, children, babies, seniors } = this;
      let str = '';
      if (adults) str += `${adults} ${this.$t('sabre.composition.adults')}`;
      if (children) str += ` ${children} ${this.$t('sabre.composition.children')}`;
      if (babies) str += ` ${babies} ${this.$t('sabre.composition.infants')}`;
      if (seniors) str += ` ${seniors} ${this.$t('sabre.composition.seniors')}`;
      return str;
    },
    isLangCodeEn() {
      return this.$route.query?.langCode === 'ENG';
    },
  },
  data() {
    return {
    };
  },
  methods: {
    showString(index, isFrom) {
      const { destinations, chosenStoreDest, chosenMultiCityStoreDest, isLangCodeEn, countries } = this;
      const origin = (index) ? chosenMultiCityStoreDest : chosenStoreDest;
      const dest = (isFrom) ? origin.from[0] : origin.to[0];
      if (!dest) return '';
      const current = destinations?.find((item) => item.code === dest) || '';
      const currentCountry = countries?.find((item) => item.code === current.countryCode)?.name || '';

      return `${isLangCodeEn ? current.name : current.name_Loc.content} - ${dest} - ${currentCountry}`;
    },
    convertDateFormat(date) {
      return dayjs(date).format('MM/DD/YYYY');
    },
  },
};
</script>
